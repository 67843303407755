<template>
  <layout v-if="user">
    <v-container fluid>
      <div align="center">
        <v-icon size="150">mdi-emoticon-sad-outline</v-icon>
        <h1 class="display-2">404</h1>
        <h2 class="display-1">Not Found</h2>
        <p class="headline">The page you are looking for does not exist or an other error occured.</p>
        <p class="headline">
          <a @click="$router.back()">Go back</a>, or head over to <router-link to="/">le-navet.com</router-link> to choose a new direction.
        </p>
      </div>
    </v-container>
  </layout>
  <v-container v-else fluid>
    <div align="center">
      <v-icon size="150">mdi-emoticon-sad-outline</v-icon>
      <h1 class="display-2">404</h1>
      <h2 class="display-1">Not Found</h2>
      <p class="headline">The page you are looking for does not exist or an other error occured.</p>
      <p class="headline">
        <a @click="$router.back()">Go back</a>, or head over to <router-link to="/">le-navet.com</router-link> to choose a new direction.
      </p>
    </div>
  </v-container>
</template>

<script>
import Layout from '../Layout';
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  metaInfo: {
    title: 'Error'
  },
  data () {
    return {
    };
  },
  components: {
    Layout
  },
  computed: {
    user () {
      return firebase.auth().currentUser;
    }
  },
  methods: {
  },
  mounted: function () {
  }
};
</script>
